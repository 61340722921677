
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import {defineComponent, ref } from "vue"
import { hideModalById, listenerHideModal } from "@/core/helpers/dom";
import { getModule } from 'vuex-module-decorators'
import {  Form, Field } from "vee-validate";
import * as Validations from "yup";
import NewSaleModule from "@/store/modules/sales/modules/new-sales";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";


export default defineComponent({
    components:{
        DynamicContentModal,
        Form,
        Field
    },
    props:{
        idSale: {
            type: String
            , required: true
        },
        action: {
          type: Number
          , required: true
        },
        isSale: {
          type: Boolean
          , required: true
        }
    },
    emits: ['open-modal'],
    setup(props, {emit}) {
        //Propiedades
        const moduleNewSale = getModule(NewSaleModule);
        // const moduleSalesType = getModule(SaleTypeModule);
        const formSenToCash = ref();
        // const formSave = ref(false);
        const router = useRouter();
        
        //Schema
        const validationSchema = Validations.object().shape({
              receiver: Validations.string().nullable().default(null).required().label("receiver"),
              observation: Validations.string().nullable().default(null).label("observation")
        });

        // moduleSalesType.SEARCH_SALES_TYPE({
        //   filterName: ""
        // });
        
        //METODOS
        const sendPay = async(data: any) => {

            await moduleNewSale.SEN_TO_PAY({
                    saleId: props.idSale,
                    observation: data.observation,
                    receiver: data.receiver
                  });

            if(props.isSale){
                router.back();
            }
        }

        const sendSCHEDULE = async(data: any) => {

        await  moduleNewSale.SEN_TO_SCHEDULE({
                      saleId: props.idSale,
                      observation: data.observation,
                      receiver: data.receiver
                    });;

        if(props.isSale){
            router.back();
        }
        }

        const submitForm = (data: any) => {
            
            if(props.action == 1){
                if(props.isSale){
                    sendPay(data);
                }else{
                  Swal.fire({
                  title: '¿Está seguro de pasar a pagos esta venta?',
                  text: "Se procederá a pasar a pagos la venta, y no podrá revertir la acción",
                  icon: 'info',
                  showCancelButton: true,
                  confirmButtonColor: '#ffb300',
                  cancelButtonColor: '#7E829',
                  confirmButtonText: 'Si, pagar'
                  }).then(async(result) => {
                  if (result.isConfirmed) {
                      sendPay(data);
                  }});
                }
            }else{
              if(props.isSale){
                sendSCHEDULE(data);
                }else{
              Swal.fire({
                title: '¿Está seguro de pasar a programación de entregas?',
                text: "Se procederá a pasar a programación de entregas, y no podrá revertir la acción",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#ffb300',
                cancelButtonColor: '#7E829',
                confirmButtonText: 'Si, aceptar'
                }).then((result) => {
                if (result.isConfirmed) {
                  sendSCHEDULE(data);
                }});
              }
            }
        

            hideModalById("#ModalSenToCash");
        };

        
        const resetForm = () => {
            formSenToCash.value.resetForm();
        }

        return {
            validationSchema
            ,formSenToCash

            ,submitForm
            ,sendPay
            ,resetForm
            ,sendSCHEDULE
        }

    },
    methods:{
        closeListener(){
            this.resetForm();
        },
        triggerHidden: function(){
            listenerHideModal("#ModalSenToCash", this.closeListener);
        },
    },
    mounted(){
        this.triggerHidden();
    }
})
