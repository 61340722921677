import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-4 mb-8" }
const _hoisted_2 = { class: "col fv-row" }
const _hoisted_3 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_4 = { class: "col-md-6 fv-row" }
const _hoisted_5 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_6 = { class: "d-flex flex-column mb-8 fv-row" }
const _hoisted_7 = { class: "d-flex align-items-center fs-6 fw-bold mb-2" }
const _hoisted_8 = { class: "row g-4 mb-8" }
const _hoisted_9 = { class: "col fv-row" }
const _hoisted_10 = { class: "d-flex align-items-center fs-6 fw-bold mb-2" }
const _hoisted_11 = { class: "col-md-6 fv-row" }
const _hoisted_12 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_13 = { class: "row g-9 mb-8" }
const _hoisted_14 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_15 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_16 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_17 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_18 = { class: "row g-9 mb-8" }
const _hoisted_19 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_20 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_21 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_22 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_23 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_24 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_25 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_26 = { class: "fs-6 fw-bold mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("LABELS.RFC")), 1),
        _createVNode(_component_InputText, {
          name: 'rfc',
          mask: _ctx.InputTextMaskTypes.Uppercase
        }, null, 8, ["mask"])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("LABELS.FOLIO")), 1),
        _createVNode(_component_InputText, {
          name: 'folio',
          disabled: true
        })
      ], 512), [
        [_vShow, _ctx.isUpdateForm]
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("LABELS.NAME")), 1),
      _createVNode(_component_InputText, { name: 'name' })
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("LABELS.EMAIL")), 1),
        _createVNode(_component_InputText, { name: 'email' })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("LABELS.CUSTOMER_TYPE")), 1),
        _createVNode(_component_Select, {
          name: 'customerType',
          options: _ctx.customerTypes
        }, null, 8, ["options"])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t("LABELS.PHONE")), 1),
        _createVNode(_component_InputText, {
          name: 'phone',
          mask: _ctx.InputTextMaskTypes.PhoneMex
        }, null, 8, ["mask"])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("LABELS.CURP")), 1),
        _createVNode(_component_InputText, {
          name: 'curp',
          mask: _ctx.InputTextMaskTypes.Uppercase
        }, null, 8, ["mask"])
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t("LABELS.PAYMENT_METHOD")), 1),
        _createVNode(_component_Select, {
          name: 'paymentMethodId',
          options: _ctx.paymentMethods
        }, null, 8, ["options"])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t("LABELS.WAY_TO_PAY")), 1),
        _createVNode(_component_Select, {
          name: 'wayToPayId',
          options: _ctx.wayToPayOptions
        }, null, 8, ["options"])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t("LABELS.USE_CFDI")), 1),
        _createVNode(_component_Select, {
          name: 'typeUseCFDIId',
          options: _ctx.useOptions
        }, null, 8, ["options"])
      ]),
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t("LABELS.REGIME")), 1),
        _createVNode(_component_Select, {
          name: 'regimeId',
          options: _ctx.regimeOptions
        }, null, 8, ["options"])
      ])
    ])
  ]))
}