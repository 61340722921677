<i18n>
{
    "es": {
        "LABELS":{
            "HAVE_CREDIT":"¿Tiene Credito?"
            , "DAYS_CREDIT":"Dias Credito"
            , "PROMPT_PAYMENT_DAYS":"Dias Pronto Pago"
            , "CREDIT_LIMIT":"Limite de Credito"
            , "PROMPT_PAYMENT_RATE":"% Pronto Pago"
            , "YES":"Si"
            , "NO":"No"
        }
    }
}
</i18n>
<template>
    <section>
        <div class="row g-4 mb-8">
            <div class="col-sm-12 fv-row">
                <label class="fs-6 fw-bold mb-2">{{ $t("LABELS.HAVE_CREDIT") }}</label>
                <Switch :name="'haveCredit'" 
                    :activeText="$t('LABELS.YES')"
                    :inactiveText="$t('LABELS.NO')"
                @change="changeHaveCredit"></Switch>
            </div>
            <div class="col-md-6 col-sm-12 fv-row">
                <label class="fs-6 fw-bold mb-2">{{ $t("LABELS.DAYS_CREDIT") }}</label>
                <InputNumber :name="'daysCredit'"  
                    ref="daysCredit"
                    :disabled="!haveCredit"></InputNumber>
            </div>
            <div class="col-md-6 col-sm-12 fv-row">
                <label class="fs-6 fw-bold mb-2">{{ $t("LABELS.PROMPT_PAYMENT_DAYS") }}</label>
                <InputNumber :name="'promptPaymentDays'"
                    ref="promptPaymentDays"
                    :disabled="!haveCredit"></InputNumber>
            </div>
            <div class="col-md-6 col-sm-12 fv-row">
                <label class="fs-6 fw-bold mb-2">{{ $t("LABELS.CREDIT_LIMIT") }}</label>
                <InputNumber :name="'creditLimit'"
                    ref="creditLimit" 
                    :disabled="!haveCredit"></InputNumber>
            </div>
            <div class="col-md-6 col-sm-12 fv-row">
                <label class="fs-6 fw-bold mb-2">{{ $t("LABELS.PROMPT_PAYMENT_RATE") }}</label>
                <InputNumber :name="'promptPaymentRate'"
                    ref="promptPaymentRate"
                    :disabled="!haveCredit"></InputNumber>
            </div>
        </div>
        <!-- -->
    </section>
</template>

<script>
import { defineComponent, ref } from "vue";
import Switch from "@/components/forms/Switch.vue" 
import InputNumber from "@/components/forms/InputNumber.vue" 
import { InputTextMaskTypes } from '@/core/enums/input-text-mask-types';

export default defineComponent({
    components:{
        Switch
        , InputNumber
    },
    setup(){
        const haveCredit = ref(false);
        const daysCredit = ref(null);
        const promptPaymentDays = ref(null);
        const creditLimit = ref(null);
        const promptPaymentRate = ref(null);

        const changeHaveCredit = (value) => {
            haveCredit.value = value;
            if(!value){

                daysCredit.value.clear();
                promptPaymentDays.value.clear();
                creditLimit.value.clear();
                promptPaymentRate.value.clear();
            }
        }

        return {
            InputTextMaskTypes
            , haveCredit
            , changeHaveCredit
            , daysCredit
            , promptPaymentDays
            , creditLimit
            , promptPaymentRate
        }
    }
})
</script>

<style>

</style>