
import { computed, defineComponent, toRefs, ref, onMounted, watch } from "vue";
import {  useForm } from "vee-validate"
import { FormTypes } from "@/core/enums/form-types"
import * as Validations from "yup";
import { RfcFormat, Phone10Digits } from "@/core/shared/ValidationRegex";
import FieldsGeneral from './FieldsGeneral.vue'
import FieldsHomeAddress from './FieldsHomeAddress.vue'
import FieldsDelivery from './FieldsDelivery.vue'
import FieldsCredit from './FieldsCredit.vue'
import FieldsCollection from './FieldsCollection.vue'
import NewClient from "@/models/clients/form/NewClient";
import { StepperComponent } from "@/assets/ts/components";
import HomeAddress from "@/models/clients/HomeAddress";
import GeneralClientInfo from "@/models/clients/GeneralClientInfo";
import Delivery from "@/models/clients/Delivery";
import Credit from "@/models/clients/Credit";
import Collection from "@/models/clients/Collection";
import { getModule } from "vuex-module-decorators";
import NewClientModule from "@/store/modules/client/modules/new-client";
import { hasValueAllProp } from "@/core/shared/functions/CheckerProperties";
import { possibleValues } from "@/core/enums/customer-type";

export default defineComponent({
    emits: ['close-content'],
    components:{
         FieldsGeneral
         , FieldsHomeAddress
         , FieldsDelivery
         , FieldsCredit
         , FieldsCollection
    }
    , props:{
        formType: {//indica como se comportara el formulario
            type: Number
            , default: FormTypes.Create
        }
    }
    , setup(props, { emit }){
        //PROPS
        const module = getModule(NewClientModule);
        const _stepperObj = ref<StepperComponent | null>(null);
        const formClientWizard = ref<HTMLElement | null>(null);
        const currentStepIndex = ref(1);
        const formData = ref<NewClient>({
            name: ''
            , rfc: ''
            , email: ''
            , wayToPayId: ''
            , paymentMethodId: ''
            , typeUseCFDIId: ''
            , phone: ''
            , curp: ''
            , homeAddress : null
            , delivery : null
            , credit : null
            , collection : null
            , customerType: null
            , regimeId: ''
            /*, homeAddress: {
                street: ''
                , number: ''
                , interiorNumber: ''
                , crosses: ''
                , block: ''
                , populationId: ''
                , zipCode: ''
                , countryId: ''
                , fax: ''
                , stateId: ''
            },
            delivery: {
                shipperId: ''
                , roadId: ''
                , typeId: ''
                , agentId: ''
                , priceTypeId: ''
                , discountRate: 0.0
            },
            credit: {
                haveCredit: false
                , daysCredit: 0
                , promptPaymentDays: 0
                , creditLimit: 0
                , promptPaymentRate: 0
            },
            collection: {
                zoneId: ''
            }*/
        });
        
        const { formType } = toRefs(props);
        const validationSchema = [
            Validations.object().shape({
                rfc: Validations.string().optional().matches(RfcFormat, { excludeEmptyString: true, message: 'Incorrect Format RFC' })
                , folio: Validations.string().default('')
                , name: Validations.string().default('').required().max(50)
                , curp: Validations.string().default('').optional().min(18).max(18)
                , email: Validations.string().default('').email().required()//.when(['rfc'], (rfc: string, schema) => {
                 //   return rfc && (rfc.length > 0) ? schema.required() : schema.optional()
                // })
                , phone: Validations.string().matches(Phone10Digits, { excludeEmptyString: true, message: 'Formato numerico de telefono incorrecto'}).default('').required()
                , wayToPayId: Validations.string().required()
                , paymentMethodId: Validations.string().required()
                , typeUseCFDIId: Validations.string().required()//USO DEL CFDI
                , customerType: Validations.mixed().required().oneOf([...possibleValues], "Seleccione una opción")
                , regimeId: Validations.string()
            }),
            Validations.object().default(null).shape({
                street: Validations.string().optional().max(50)
                , number: Validations.string().default('Sin número').optional().max(10)
                , interiorNumber: Validations.string().optional().max(3)
                , crosses: Validations.string().optional().max(50)
                , block: Validations.string().optional().max(50)
                , populationId: Validations.string().required()
                , zipCode: Validations.string().optional().max(5)
                , countryId: Validations.string().optional()
                , fax: Validations.string().optional().max(10)
                , stateId: Validations.string().optional()
            }),
            Validations.object().shape({
                shipperId: Validations.string().optional()
                , roadId: Validations.string().optional()
                , typeId: Validations.string().optional()
                , agentId: Validations.string().optional()
                , priceTypeId: Validations.string().optional()
                , discountRate: Validations.number().optional()

            }),
            Validations.object().shape({
                haveCredit: Validations.boolean().default(false)
                , daysCredit: Validations.number().optional()
                , promptPaymentDays: Validations.number().optional()
                , creditLimit: Validations.number().optional()
                , promptPaymentRate: Validations.number().optional()
            }),
            Validations.object().shape({
                zoneId: Validations.string().optional()
            })
        ]
        const currentSchema = computed(() => {
            return validationSchema[currentStepIndex.value - 1];
        });
        const { resetForm, handleSubmit, meta, setValues } = useForm<GeneralClientInfo | HomeAddress | Delivery | Credit | Collection>({
            validationSchema: currentSchema.value,
        });
        //METODOSd
        const submitForm = () => {
            let actionModule: Promise<any>;
            if(formType.value == FormTypes.Create){
                actionModule = module.SAVE_NEW_CLIENT(formData.value);
            } else {
                actionModule = module.UPDATE_CLIENT(formData.value);
            }
            actionModule.then((isCorrect) => {
                if(isCorrect){
                    emit("close-content", isCorrect.id)
                    resetForm()
                }
            })
        }
        /**
         * Asigna el valor a los datos
         */
        const setValueFormData = (values: GeneralClientInfo | HomeAddress | Delivery | Credit | Collection | NewClient, inForm = false) => {
            let newValues: GeneralClientInfo | HomeAddress | Delivery | Credit | Collection | null = null;
            if(currentStepIndex.value == 1){
                const valueGeneral = values as GeneralClientInfo;
                newValues = {
                     rfc: valueGeneral.rfc
                    , name: valueGeneral.name
                    , email: valueGeneral.email
                    , phone: valueGeneral.phone
                    , curp: valueGeneral.curp
                    , wayToPayId: valueGeneral.wayToPayId
                    , paymentMethodId: valueGeneral.paymentMethodId
                    , typeUseCFDIId: valueGeneral.typeUseCFDIId
                    , customerType: valueGeneral.customerType
                    , regimeId: valueGeneral.regimeId
                } as GeneralClientInfo
                formData.value = {
                    ...formData.value,
                    ...newValues
                };
                
            } else if(currentStepIndex.value == 2){//asi sabemos si es de tipo HomeAddress por que esta propiedad solo esta en esa interface
                const valueHomeAddress = values as HomeAddress;
                newValues = {
                    street: valueHomeAddress.street
                    , crosses: valueHomeAddress.crosses
                    , block: valueHomeAddress.block
                    , number: valueHomeAddress.number
                    , interiorNumber: valueHomeAddress.interiorNumber
                    , populationId: valueHomeAddress.populationId
                    , countryId: valueHomeAddress.countryId
                    , stateId: valueHomeAddress.stateId
                    , zipCode: valueHomeAddress.zipCode
                    , fax: valueHomeAddress.fax
                } as HomeAddress

                hasValueAllProp(newValues) ?
                formData.value = {
                    ...formData.value,
                    ...newValues
                } : false
            } else if(currentStepIndex.value == 3) {//asi verificamos si son los datos de Condition
                const valueDelivery = values as Delivery;
                newValues = {
                    shipperId: valueDelivery.shipperId
                    , roadId: valueDelivery.roadId
                    , typeId: valueDelivery.typeId
                    , agentId: valueDelivery.agentId
                    , priceTypeId: valueDelivery.priceTypeId
                    , discountRate: valueDelivery.discountRate
                } as Delivery

                hasValueAllProp(newValues) ?
                formData.value = {
                    ...formData.value,
                    ...newValues
                } : false
            } else if(currentStepIndex.value == 4) {//asi verificamos si son los datos de Condition
                const valueCredit = values as Credit;
                newValues = {
                    haveCredit: valueCredit.haveCredit
                    , daysCredit: valueCredit.daysCredit
                    , promptPaymentDays: valueCredit.promptPaymentDays
                    , creditLimit: valueCredit.creditLimit
                    , promptPaymentRate: valueCredit.promptPaymentRate
                } as Credit
                hasValueAllProp(newValues) ?
                formData.value = {
                    ...formData.value,
                    ...newValues
                } : false
            } else if(currentStepIndex.value == 5) {//asi verificamos si son los datos de Condition
                const valueCollection = values as Collection;
                newValues =  {
                    zoneId: valueCollection.zoneId
                } as Collection

                hasValueAllProp(newValues) ?
                formData.value = {
                    ...formData.value,
                    ...newValues
                } : false
               
            }

            
            /*if(inForm && newValues){
            
            }*/
        }
        const handleStep = handleSubmit((values) => {//NewClient
            setValueFormData(values as NewClient);
        });

        const saveClient = async () => {
            //guarda la información del cliente
            //verificar si el formulario actual es correcto
            let formIsValid = false;
            currentStepIndex.value = 1;
            
            // si es valido verificamos los demas
            while (currentStepIndex.value <= 5) {
                await handleStep();//primero verificamos si es valido el formulario
                if(!meta.value.valid){
                    //si no es valido entonces me debe llevar hasta ese formulario
                    goTo(currentStepIndex.value);
                    return;
                } else if(currentStepIndex.value == 5){//el ultimo registro
                    //si es el ultimo registro y es valido entonces es correcto el formulario
                        formIsValid = true;
                } 
                currentStepIndex.value++;
                 
            }
            if(formIsValid){
                currentStepIndex.value = 1;
                submitForm();
            }
            
            //verificar si todos los formularios estan correctos
        }

        const previousStep = () => {
            if (!_stepperObj.value) {
                return;
            }

            currentStepIndex.value--;

            _stepperObj.value.goPrev();
        };
        const totalSteps = computed(() => {
            if (!_stepperObj.value) {
                return;
            }

            return _stepperObj.value.totatStepsNumber;
        });
        
        const goTo = (index: number) => {
            if (!_stepperObj.value) {
                return;
            }

            currentStepIndex.value = index;

            _stepperObj.value.goto(index);
        }
        const cleanPopulation = () => {
             currentStepIndex.value = 2;
             setValues({
                populationId: ''
             });
        }
        const cleanState = () => {
             currentStepIndex.value = 2;
             setValues({
                stateId: ''
             });
        }
        const IsUpdateForm = computed(() => module.IsUpdateForm)

        watch(() => module.getValuesForm, (newValueForm) => {
            //se asigna los valores del formulario
            currentStepIndex.value = 1;
            //await new Promise(r => setTimeout(r, 2000));
            // si es valido verificamos los demas
            while (currentStepIndex.value <= 5) {
                //asignamos los valores para cada formulario
                //setValueFormData(newValueForm, true);
                setValues(newValueForm);
                currentStepIndex.value++;
            }
            currentStepIndex.value = 1

        });
        //from store

        //
        onMounted(() => {
            _stepperObj.value = StepperComponent.createInsance(
                formClientWizard.value as HTMLElement
                , {
                    useCompletedState: false
                    , animation: true
                }
            );
        })
        return {
            submitForm
            , handleStep
            , previousStep
            , goTo
            , currentStepIndex
            , totalSteps
            , formClientWizard
            , formData
            , saveClient
            , cleanPopulation
            , cleanState
        }
    }
})
