
import { defineComponent, computed } from "vue";
import Select from "@/components/forms/Select.vue" 
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue" 
import InputNumber from "@/components/forms/InputNumber.vue" 
import { InputTextMaskTypes } from '@/core/enums/input-text-mask-types';
import { getModule } from "vuex-module-decorators";
import NewClientModule from "@/store/modules/client/modules/new-client";
import _ from "lodash";

export default defineComponent({
    components:{
        Select
        , InputNumber
        , SelectFilterRemote
    },
    setup(){
        const module = getModule(NewClientModule);
        
        const shipperOptions = computed(() => []/*call store*/);
        const roadOptions = computed(() => []/*call store*/);
        const typeOptions = computed(() => []/*call store*/);
        const agentOptions = computed(() => module.selects.agentOptions);
        const priceOptions = computed(() => []/*call store*/);

        const searchAgent = _.debounce((query: string) => {
            module.getComboAgent(query);
        }, 550, {leading: false, trailing: true});//(filtername: string) => 

        return {
            shipperOptions
            , roadOptions
            , typeOptions
            , agentOptions
            , priceOptions
            , InputTextMaskTypes
            , searchAgent
        }
    }

})
