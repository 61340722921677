
import { defineComponent, computed, ref } from "vue";
import InputText from "@/components/forms/InputText.vue" 
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue" 
import NewClientModule from "@/store/modules/client/modules/new-client";
import { getModule } from "vuex-module-decorators";
import l from "lodash";

export default defineComponent({
    components:{
        InputText
        , SelectFilterRemote
    },
    emits: ['cleanStateSelected', 'cleanPopulationSelected']
    ,setup(_, { emit }){
        const module = getModule(NewClientModule);
        const disabledState = ref(true);
        const countryId = ref('');//pais seleccionado

        const disabledPopulation = ref(true);
        const stateId = ref('');//pais seleccionado

        //from store
        const populationOptions = computed(() => module.selects.populationOptions);
        const stateOptions = computed(() => module.selects.stateOptions);
        const countryOptions = computed(() => module.selects.countryOptions);

        const countryChange =  l.throttle((idSelected: string) => { //(value: string) => {
            //si cambia el valor indicar que ya se puede activar el otro control
            disabledState.value = false;
            countryId.value = idSelected;
         },650, {leading: false});
        const searchCountry = l.debounce((query: string) => {
            module.getComboCountries(query)
        }, 550, {leading: false, trailing: true})
        const clearComboState = () => {
            disabledState.value = true;
            countryId.value = '';
            emit('cleanStateSelected')
        }

        const searchState = l.debounce((query: string) => {
            module.getComboStates({ filterName: query, countryId: countryId.value });
        }, 550, {leading: false, trailing: true})//(filtername: string) => 
        const stateChange =  l.throttle((idSelected: string) => {//(value: string) => {
            //si cambia el valor indicar que ya se puede activar el otro control
            disabledPopulation.value = false;
            stateId.value = idSelected;
        },650, {leading: false});

        const clearComboPopulation = () => {
            disabledPopulation.value = true;
            stateId.value = '';
            emit('cleanPopulationSelected')
        }
        const searchPopulation = l.debounce((query: string) => {
            module.getComboPopulations({ filterName: query, countryId: countryId.value, stateId: stateId.value });
        }, 550, {leading: false, trailing: true})//(filtername: string) => ;

        return {
            populationOptions
            , countryOptions
            , stateOptions
            , disabledPopulation
            , disabledState
            , countryChange
            , searchCountry
            , clearComboState
            , stateChange
            , searchState
            , clearComboPopulation
            , searchPopulation
        }
    }
})
