import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-4 mb-8" }
const _hoisted_2 = { class: "col-12 fv-row" }
const _hoisted_3 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_4 = { class: "col-12 fv-row" }
const _hoisted_5 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_6 = { class: "col-12 fv-row" }
const _hoisted_7 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_8 = { class: "col-12 fv-row" }
const _hoisted_9 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_10 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_11 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_12 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_13 = { class: "fs-6 fw-bold mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_SelectFilterRemote = _resolveComponent("SelectFilterRemote")!
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("LABELS.SHIPPER")), 1),
        _createVNode(_component_Select, {
          name: 'shipperId',
          options: _ctx.shipperOptions
        }, null, 8, ["options"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("LABELS.ROAD")), 1),
        _createVNode(_component_Select, {
          name: 'roadId',
          options: _ctx.roadOptions
        }, null, 8, ["options"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("LABELS.TYPE")), 1),
        _createVNode(_component_Select, {
          name: 'typeId',
          options: _ctx.typeOptions
        }, null, 8, ["options"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("LABELS.AGENT")), 1),
        _createVNode(_component_SelectFilterRemote, {
          name: 'agentId',
          placeholder: _ctx.$t('PLACE_HOLDERS.AGENT'),
          options: _ctx.agentOptions,
          onSearch: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchAgent($event)))
        }, null, 8, ["placeholder", "options"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("LABELS.PRICE")), 1),
        _createVNode(_component_Select, {
          name: 'priceTypeId',
          options: _ctx.priceOptions
        }, null, 8, ["options"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("LABELS.DISCOUNT_RATE")), 1),
        _createVNode(_component_InputNumber, {
          name: 'discountRate',
          max: 99.99,
          min: 0,
          precision: 2,
          step: 0.1
        }, null, 8, ["max", "step"])
      ])
    ])
  ]))
}