export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "SHIPPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fletera"])},
          "ROAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruta"])},
          "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
          "AGENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente"])},
          "PRICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
          "DISCOUNT_RATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% Descuento"])}
        },
        "PLACE_HOLDERS": {
          "AGENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtra por nombre"])}
        }
      }
    }
  })
}
