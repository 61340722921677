import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = {
  class: "stepper stepper-links d-flex flex-column h-100",
  id: "create-client-stepper",
  ref: "formClientWizard"
}
const _hoisted_4 = { class: "stepper-nav py-5 mt-5" }
const _hoisted_5 = { class: "stepper-title" }
const _hoisted_6 = { class: "stepper-title" }
const _hoisted_7 = { class: "stepper-title" }
const _hoisted_8 = { class: "stepper-title" }
const _hoisted_9 = { class: "stepper-title" }
const _hoisted_10 = {
  class: "current",
  "data-kt-stepper-element": "content"
}
const _hoisted_11 = { "data-kt-stepper-element": "content" }
const _hoisted_12 = { "data-kt-stepper-element": "content" }
const _hoisted_13 = { "data-kt-stepper-element": "content" }
const _hoisted_14 = { "data-kt-stepper-element": "content" }
const _hoisted_15 = { class: "d-flex flex-stack pt-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldsGeneral = _resolveComponent("FieldsGeneral")!
  const _component_FieldsHomeAddress = _resolveComponent("FieldsHomeAddress")!
  const _component_FieldsDelivery = _resolveComponent("FieldsDelivery")!
  const _component_FieldsCredit = _resolveComponent("FieldsCredit")!
  const _component_FieldsCollection = _resolveComponent("FieldsCollection")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("a", {
              class: "stepper-item selectable current",
              "data-kt-stepper-element": "nav",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goTo(1)))
            }, [
              _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("TITLES.GENERAL")), 1)
            ]),
            _createElementVNode("a", {
              class: "stepper-item selectable",
              "data-kt-stepper-element": "nav",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goTo(2)))
            }, [
              _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t("TITLES.HOME_ADDRESS")), 1)
            ]),
            _createElementVNode("a", {
              class: "stepper-item selectable",
              "data-kt-stepper-element": "nav",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goTo(3)))
            }, [
              _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t("TITLES.DELIVERY")), 1)
            ]),
            _createElementVNode("a", {
              class: "stepper-item selectable",
              "data-kt-stepper-element": "nav",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goTo(4)))
            }, [
              _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t("TITLES.CREDIT")), 1)
            ]),
            _createElementVNode("a", {
              class: "stepper-item selectable",
              "data-kt-stepper-element": "nav",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.goTo(5)))
            }, [
              _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t("TITLES.COLLECTION")), 1)
            ])
          ]),
          _createElementVNode("form", {
            class: "mx-auto mw-600px w-100 pt-15 pb-10",
            onSubmit: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleStep && _ctx.handleStep(...args)))
          }, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_FieldsGeneral)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_FieldsHomeAddress, {
                onCleanPopulationSelected: _ctx.cleanPopulation,
                onCleanStateSelected: _ctx.cleanState
              }, null, 8, ["onCleanPopulationSelected", "onCleanStateSelected"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_FieldsDelivery)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_FieldsCredit)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_FieldsCollection)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-lg btn-primary me-3",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.saveClient()))
              }, _toDisplayString(_ctx.$t("BUTTONS.SUBMIT")), 1)
            ])
          ], 32)
        ], 512)
      ])
    ])
  ]))
}