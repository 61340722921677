
import { defineComponent, computed, ref } from "vue";
import InputText from "@/components/forms/InputText.vue";
import Select from "@/components/forms/Select.vue";
import { InputTextMaskTypes } from "@/core/enums/input-text-mask-types";
import { getModule } from "vuex-module-decorators";
import NewClientModule from "@/store/modules/client/modules/new-client";
import { getOptions } from "@/core/enums/customer-type";
export default defineComponent({
  components: {
    InputText,
    Select,
  },
  setup() {
    const module = getModule(NewClientModule);
    //from store
    const wayToPayOptions = computed(() => module.selects.wayToPayOptions);
    const paymentMethods = computed(() => module.selects.paymentMethodsOptions);
    const useOptions = computed(() => module.selects.cfdiOptions);
    const isUpdateForm = computed(() => module.IsUpdateForm /*call store*/);
    const customerTypes = ref(getOptions());
    const regimeOptions = computed(() => module.selects.regimeOptions)

    return {
      wayToPayOptions,
      paymentMethods,
      useOptions,
      InputTextMaskTypes,
      isUpdateForm,
      customerTypes,
      regimeOptions,
    };
  },
});
