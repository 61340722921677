/**
 * Verifica si todas las propiedades de un objeto son diferentes a undifined, vacio o nulo
 * @param amount cantidad en formato de moneda
 * @returns un numero con el valor correspondiente
 */
const hasValueAllProp = (obj): boolean => {
    for (const key in obj) {
        if (obj[key] !== undefined && obj[key] !== null && obj[key] != "")
            return false;
    }
    return true;
}

export {hasValueAllProp}