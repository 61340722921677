
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import {computed, defineComponent, onBeforeUnmount, onMounted, ref, toRefs, watch } from "vue"
import { hideModalById, listenerHideModal } from "@/core/helpers/dom";
import { getModule } from 'vuex-module-decorators'
import {  Form } from "vee-validate";
import * as Validations from "yup";
import NewSaleModule from "@/store/modules/sales/modules/new-sales";
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import Select from "@/components/forms/Select.vue"
import SaleTypeModule from "@/store/modules/saleType/modules/new-saletype";
import InfoSale from "@/models/sales/InfoSale";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/plugins/i18n";
import { SalesTypes } from "@/core/enums/sales-types";
import OptionSelect from "@/models/shared/OptionSelect";
// import hotkeys from "hotkeys-js";

export default defineComponent({
    components:{
        DynamicContentModal,
        Form,
        Select,
        SelectFilterRemote
    },
    props: {
       isActionAdd: {
           type: Number,
           default: SalesTypes.CreateSale
       }
    },
    emits: ['open-modal', 'new-cliente'],
    setup(props, {emit}) {
        //Propiedades
        const moduleNewSale = getModule(NewSaleModule);
        const moduleSalesType = getModule(SaleTypeModule);
        const saveFromNewSale = ref(HTMLButtonElement);
        const formSaleInital = ref();
        const formSave = ref(false);
        const router = useRouter();
        const { isActionAdd } = toRefs(props);
        
        //Schema
        const validationSchema = Validations.object().shape({
          customerId: Validations.string().required().label("customerId"),
              saleTypeId: Validations.string().required().label("saleTypeId"),
              // receiver: Validations.string().nullable().default(null).required().label("receiver"),
              // observation: Validations.string().nullable().default(null).required().label("observation")
        });
        
        //METODOS
        const handleSearchClient = (name: string) => {
          if(name != "")
              moduleNewSale.SEARCH_CLIENTS(name);
        }

        const handlerChangeClient = (id: string) => {
          moduleNewSale.SAVE_CLIENT_SELECTED(id);
        }

        const handlerNEwCliente = () => {
            formSave.value = true;
            emit("new-cliente");
            hideModalById("#ModalNewSaleForm");
        }   

        const handlerEnter = () => {
          console.log("enter");
        }

        const onchangeType = () => {
          console.log("press");
        }

        const submitForm = (data: any) => {
            const saleDataInital = {
                customerId: data.customerId,
                observation: data.observation,
                receiver: data.receiver,
                saleTypeId: data.saleTypeId
            } as InfoSale;

            moduleNewSale.SET_INITIAL_DATA_SALE(saleDataInital);
            moduleNewSale.SAVE_CLIENT_SELECTED(data.customerId);

            formSave.value = true;

            hideModalById("#ModalNewSaleForm");
        };

        const clientsCombo = computed(() => moduleNewSale.getClientOptions);
        const loadingClientCombo = computed(() => moduleNewSale.loadingClients);
        const loadingSaleTypeCombo = computed(() => moduleSalesType.loading);
        const saleTypes = computed(() => moduleSalesType.getSaleTypeOptions);

        const resetForm = () => {
            formSaleInital.value.resetForm();

            if(!formSave.value){
              Swal.fire({
                title: `Es necesario ingresar los datos generales de la ${ isActionAdd.value == SalesTypes.CreateQuote ? 'cotización' : 'venta' }, ¿Esta seguro de abandonar la ${ isActionAdd.value == SalesTypes.CreateQuote ? 'cotización' : 'venta' }?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ffb300',
                cancelButtonColor: '#7E829',
                confirmButtonText: 'Si, salir',
                cancelButtonText: "No"
                }).then((result) => {
                  if (result.isConfirmed) {
                      setCurrentPageBreadcrumbs(translate('BREADCRUMB.SALES'), [translate('BREADCRUMB.MODULES')]);
                      router.back(); 
                  }else{
                    emit("open-modal");
                  }
              });
            }
        }

        watch(() => moduleSalesType.getSaleTypeOptions, async(currentValue: OptionSelect[]) => {//para usar el watch hay que hacerlo con base a un getter
          if(currentValue){

            const fristValue = currentValue[0];

            if(fristValue){
              formSaleInital.value.setFieldValue('saleTypeId', fristValue.id);

            }
          }
        })

        return {
            validationSchema
            ,handlerNEwCliente
            ,clientsCombo
            ,saleTypes
            ,formSaleInital
            ,loadingClientCombo
            ,loadingSaleTypeCombo
            ,SalesTypes

            ,onchangeType
            ,submitForm
            ,handleSearchClient
            ,handlerChangeClient
            ,resetForm
            ,handlerEnter
        }

    },
    methods:{
        closeListener(){
            this.resetForm();
        },
        triggerHidden: function(){
            listenerHideModal("#ModalNewSaleForm", this.closeListener);
        },
    },
    mounted(){
        this.triggerHidden();
    }
})
