<i18n>
{
    "es": {
        "LABELS":{
            "ZONE":"Zona"
        }
    }
}
</i18n>
<template>
    <section>
        <div class="row g-4 mb-8">
            <div class="col-12 fv-row">
                <label class="fs-6 fw-bold mb-2">{{ $t("LABELS.ZONE") }}</label>
                <Select :name="'zoneId'" :options="zoneOptions"></Select>
            </div>
        </div>
    </section>
</template>

<script>
import { computed, defineComponent } from "vue";
import Select from "@/components/forms/Select.vue"
import { InputTextMaskTypes } from '@/core/enums/input-text-mask-types';

export default defineComponent({
    components:{
        Select
    },
    setup(){
        const zoneOptions = computed(() => []/*call store*/);
        return {
            InputTextMaskTypes
            , zoneOptions
        }
    }
})
</script>

<style>

</style>